<template>
  <Carte
    style="position: relative"
    :liste-fournisseurs="listeFournisseurs"
    :recommande="recommande"
    :position="position"
    :immatriculation="immatriculation"
  />
</template>
<script>
import Carte from './carte.vue';

export default {
  components: {
    Carte,
  },
  props: {
    recommande: {
      type: Object,
      required: false,
      default: undefined,
    },
    listeFournisseurs: {
      type: Array,
      required: true,
    },
    position: {
      type: Object,
      required: true,
    },
    immatriculation: {
      type: String,
      required: true,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;

  width: 100%;
  height: 100%;
  max-width: 100%;
}
</style>
