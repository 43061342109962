<template>
  <NuxtLoadingIndicator color="var(--theme-icone)" />
  <VitePwaManifest />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <Toaster
    :value="toasterStore.toaster.actif"
    :color="toasterStore.toaster.color"
    :message="toasterStore.toaster.message"
  />
</template>

<script setup lang="ts">
const toasterStore = useToasterStore();
</script>
