// Utilisation de Pinia
import {defineStore} from 'pinia';
import type {AuthentificationResponse, AuthentificationStore} from '~/types/types';

const UNAUTHORIZED_STATUS_CODE = 401;

export const useAuthentificationStore = defineStore('authentification', () => {
  const userStore = useUserStore();
  const toasterStore = useToasterStore();
  const utilsStore = useUtilsStore();
  const vehiculeStore = useVehiculeStore();
  const loaderStore = useLoaderStore();

  const authentificationStore = ref<AuthentificationStore | null>(JSON.parse(localStorage.getItem('auth') ?? 'null'));

  watch(authentificationStore, (newValue) => {
    localStorage.setItem('auth', JSON.stringify(newValue));
  });

  function $reset() {
    authentificationStore.value = null;
    localStorage.removeItem('auth');
  }

  const setToken = (data?: string) => {
    authentificationStore.value = {...authentificationStore.value, token: data};
  };
  const setExpirationDate = (data?: Date) => {
    authentificationStore.value = {...authentificationStore.value, expirationDate: data};
  };
  const setModeConnexion = (data?: string) => {
    authentificationStore.value = {...authentificationStore.value, modeConnexion: data};
  };

  const getModeConnexion = () => {
    return authentificationStore.value?.modeConnexion;
  };

  const generateAuthCookie = (token, rememberMe) => {
    const maxAge = rememberMe ? 60 * 60 * 24 * 365 : 60 * 60;
    const expirationDate = new Date();
    expirationDate.setSeconds(expirationDate.getSeconds() + maxAge);
    setToken(token);
    setExpirationDate(expirationDate);
  };

  const loginWithCreds = async (values) => {
    loaderStore.showLoader();

    const {data, error} = await useMyFetch<AuthentificationResponse>('/copilot/authentification/credentials', {
      method: 'POST',
      body: {username: values.email, password: values.password},
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (data.value) {
      generateAuthCookie(data.value.access_token, values.rememberMe);
      userStore.setUserEmail(values.email);
      setModeConnexion('credentials');
      userStore.fetchUser(values.email);
      if (utilsStore.getTutoriel()) {
        navigateTo('/vehicules');
      } else {
        navigateTo('/tutoriel');
      }
    } else if (error.value && error.value.statusCode === UNAUTHORIZED_STATUS_CODE) {
      toasterStore.afficherLeToaster('error', 'messageErreur.invalidCredentials');
    } else if (error.value) {
      toasterStore.afficherLeToaster('error', 'messageErreur.erreurAuth');
    }

    loaderStore.hideLoader();
  };

  const loginWithImmat = async (values) => {
    loaderStore.showLoader();
    const {data, error} = await useMyFetch<AuthentificationResponse>('/copilot/authentification', {
      method: 'POST',
      body: {username: values.immat, password: values.numeroContrat},
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (data.value) {
      await generateAuthCookie(data.value.access_token, values.rememberMe);
      setModeConnexion('immat');

      await vehiculeStore.fetchVehicule(undefined);
      if (vehiculeStore.getVehicule()) {
        if (utilsStore.getTutoriel()) {
          navigateTo('/');
        } else {
          navigateTo({path: '/tutoriel'});
        }
      }
    } else if (error.value) {
      if (values.immat && values.immat.includes('@')) {
        toasterStore.afficherLeToaster('error', 'messageErreur.immatAttendue');
      } else {
        toasterStore.afficherLeToaster('error', 'messageErreur.erreurAuth');
      }
    }

    loaderStore.hideLoader();
  };

  const createAccount = async (values) => {
    loaderStore.showLoader();
    const {error} = await useMyFetch<string>('/copilot/registration', {
      method: 'POST',
      body: {email: values.email, password: values.password, matchingPassword: values.confirmedPassword},
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!error.value) {
      toasterStore.afficherLeToaster('success', 'messageSucces.compteCree');
      navigateTo({
        path: '/login',
        query: {mode: 'credentials'},
      });
    } else {
      toasterStore.afficherLeToaster('error', 'messageErreur.erreurRegistration');
    }

    loaderStore.hideLoader();
  };

  const logout = () => {
    navigateTo('/login');
    useAuthentificationStore().$reset();
    useUserStore().$reset();
    useVehiculeStore().$reset();
    useFournisseursStore().$reset();
    useAdresseStore().$reset();
  };

  const getToken = () => {
    return authentificationStore.value?.token;
  };

  const isAuthenticated = () => {
    return (
      !!authentificationStore.value?.token &&
      new Date(Date.now()) < new Date(authentificationStore.value?.expirationDate)
    );
  };

  return {
    $reset,
    authentificationStore,
    setToken,
    setExpirationDate,
    setModeConnexion,
    getToken,
    isAuthenticated,
    loginWithCreds,
    loginWithImmat,
    getModeConnexion,
    createAccount,
    logout,
  };
});
