import {defineStore} from 'pinia';

export const useLoaderStore = defineStore('loader', () => {
  const loader = ref<boolean>(false);

  const showLoader = () => (loader.value = true);

  const isLoading = () => loader.value;

  const hideLoader = () => (loader.value = false);

  return {
    showLoader,
    isLoading,
    hideLoader,
  };
});
