<template>
  <div class="btn-photo">
    <v-btn rounded="xl" color="var(--theme-bouton)" variant="outlined" @click="$refs.photoInput.click()">
      <v-icon left> mdi-camera </v-icon>
      <span class="ml-2"> Prendre une photo</span>
    </v-btn>
    <input v-show="false" ref="photoInput" accept="image/*" type="file" @change="photoPrise" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      extensionsAutorisees: ['png', 'jpg', 'jpeg', 'gif'],
      file: null,
    };
  },
  methods: {
    photoPrise(event) {
      event.stopPropagation();
      event.preventDefault();

      const file = event.target.files[0];
      const fileExtension = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase();

      if (this.extensionsAutorisees.indexOf(fileExtension) === -1) {
        this.$emit('fileError', `Format .${fileExtension} non autorisé`);
      } else {
        this.$emit('file', file);
      }
    },
  },
};
</script>
<style scoped>
.btn-photo {
  display: flex;
  justify-content: center;
  margin: 5px 2px 2px 2px;
}

/* @media screen and (max-width: 600px) {
  .v-btn {
    font-size: 11px;
  }
} */
</style>
