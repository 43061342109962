import {GoogleMap, Marker, CustomMarker, MarkerCluster} from 'vue3-google-map';
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(GoogleMap);
  nuxtApp.vueApp.use(Marker);
  nuxtApp.vueApp.use(CustomMarker);
  nuxtApp.vueApp.use(MarkerCluster);
  nuxtApp.vueApp.component('GoogleMap', GoogleMap);
  nuxtApp.vueApp.component('Marker', Marker);
  nuxtApp.vueApp.component('CustomMarker', CustomMarker);
  nuxtApp.vueApp.component('MarkerCluster', MarkerCluster);

  return {
    provide: {
      GoogleMap,
      Marker,
    },
  };
});
