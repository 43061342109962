<template>
  <v-snackbar
    v-model="toasterStore.toaster.actif"
    :content-class="props.contentClass"
    transition="slide-y-reverse-transition"
    v-bind="$attrs"
    class="toast font-size-16 d-flex"
    multi-line
    :timeout="3000"
  >
    <div class="text-center">{{ toasterStore.toaster.message }}</div>
  </v-snackbar>
</template>

<script setup lang="ts">
const props = defineProps({
  contentClass: {required: false, type: String, default: ''},
});

const toasterStore = useToasterStore();
</script>

<style>
.toast {
  color: white;
}

.v-snack__wrapper {
  margin-bottom: 0px !important;
  max-width: 400px;
  width: 50%;
}

.v-snack__content {
  text-align: center !important;
  font-weight: 700;
}

@media screen and (max-width: 500px) {
  .v-snack__wrapper {
    margin-bottom: 0px !important;
    width: 200px !important;
  }
}
</style>
