import revive_payload_client_4sVQNw7RlN from "/builds/fatec-developpement/copilot/copilot-front/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_ftx86CUdf5 from "/builds/fatec-developpement/copilot/copilot-front/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.mjs";
import vuetify_icons_RMzWu406ID from "/builds/fatec-developpement/copilot/copilot-front/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_KgADcZ0jPj from "/builds/fatec-developpement/copilot/copilot-front/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/fatec-developpement/copilot/copilot-front/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/fatec-developpement/copilot/copilot-front/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/fatec-developpement/copilot/copilot-front/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/fatec-developpement/copilot/copilot-front/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/fatec-developpement/copilot/copilot-front/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/fatec-developpement/copilot/copilot-front/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/fatec-developpement/copilot/copilot-front/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/fatec-developpement/copilot/copilot-front/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_Y6agwlnXZq from "/builds/fatec-developpement/copilot/copilot-front/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/builds/fatec-developpement/copilot/copilot-front/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import switch_locale_path_ssr_8BKffvaEi5 from "/builds/fatec-developpement/copilot/copilot-front/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/builds/fatec-developpement/copilot/copilot-front/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import vuetify_no_client_hints_wMKVLl9fyU from "/builds/fatec-developpement/copilot/copilot-front/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import GoogleMaps_dBBjtNiLpJ from "/builds/fatec-developpement/copilot/copilot-front/plugins/GoogleMaps.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/builds/fatec-developpement/copilot/copilot-front/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_i18n_ftx86CUdf5,
  vuetify_icons_RMzWu406ID,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  vuetify_no_client_hints_wMKVLl9fyU,
  GoogleMaps_dBBjtNiLpJ,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]