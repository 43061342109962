import {defineStore} from 'pinia';
import type {User} from '~/types/types';

export const useUserStore = defineStore('user', () => {
  const toasterStore = useToasterStore();
  const authentificationStore = useAuthentificationStore();
  const loaderStore = useLoaderStore();
  const user = ref<User | null>(JSON.parse(localStorage.getItem('user') ?? 'null'));

  watch(user, (newValue) => {
    localStorage.setItem('user', JSON.stringify(newValue));
  });
  function $reset() {
    user.value = null;
    localStorage.removeItem('user');
  }

  const setUser = (data?: User) => {
    user.value = data;
  };

  const setUserEmail = (data?: string) => {
    user.value = {...user.value, email: data};
  };
  const setVehiculesRattaches = (data?: VehiculeSimplifie[]) => {
    user.value = {...user.value, vehiculesRattaches: data};
  };

  const getUserEmail = () => {
    return user.value?.email;
  };

  const getVehiculesRattaches = computed(() => {
    return user.value?.vehiculesRattaches;
  });

  const getUser = () => {
    return user.value;
  };

  const getIdUser = () => {
    return user.value?.idCompte;
  };
  const initActifs = async () => {
    if (authentificationStore.isAuthenticated() && getIdUser()) {
      fetchActifs(getIdUser());
    }
  };
  const fetchUser = async (email: string) => {
    try {
      const {data, error} = await useMyFetch<User>('/copilot/comptes/user?email=' + email, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authentificationStore.getToken(),
        },
      });

      if (data.value) {
        setUser(data.value);
      } else if (error.value) {
        toasterStore.afficherLeToaster('error', 'messageErreur.erreurRecupUtilisateur');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchActifs = async (idCompte: string) => {
    loaderStore.showLoader();
    try {
      const {data, error} = await useMyFetch<VehiculeSimplifie[]>('/copilot/comptes/actifs?idCompte=' + idCompte, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authentificationStore.getToken(),
        },
      });

      if (data.value) {
        setVehiculesRattaches(data.value);
      } else if (error.value) {
        toasterStore.afficherLeToaster('error', 'messageErreur.erreurRecupVehicules');
      }
    } catch (e) {
      console.error(e);
    }
    loaderStore.hideLoader();
  };

  const sendResetPasswordEmail = async (email: string) => {
    loaderStore.showLoader();
    const {error} = await useMyFetch<string>('/copilot/demandeResetPassword', {
      method: 'POST',
      body: {email: email},
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (error.value) {
      toasterStore.afficherLeToaster('error', 'messageErreur.erreurGenerique');
    }
    loaderStore.hideLoader();
  };

  const resetPassword = async (values, token) => {
    const {error} = await useMyFetch<string>('/copilot/modifierMdp', {
      method: 'POST',
      body: {token: token, password: values.password, matchingPassword: values.confirmedPassword},
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!error.value) {
      toasterStore.afficherLeToaster('success', 'messageSucces.mdpModifie');
    } else {
      toasterStore.afficherLeToaster('error', 'messageErreur.erreurGenerique');
    }
  };

  const editPassword = async (values) => {
    loaderStore.showLoader();
    const {error} = await useMyFetch<string>('/copilot/comptes/modifierMdp', {
      method: 'POST',
      body: {
        ancienPassword: values.ancienPassword,
        password: values.password,
        matchingPassword: values.confirmedPassword,
      },
      query: {
        email: getUserEmail(),
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authentificationStore.getToken(),
      },
    });
    if (!error.value) {
      toasterStore.afficherLeToaster('success', 'messageSucces.mdpModifie');
    } else {
      toasterStore.afficherLeToaster('error', 'messageErreur.erreurGenerique');
    }
    loaderStore.hideLoader();
    authentificationStore.logout();
  };

  const ajouterVehicule = async (immatriculation: string, numeroDossier: string) => {
    loaderStore.showLoader();

    const {error} = await useMyFetch<string>('/copilot/comptes/' + getIdUser() + '/ajouter', {
      method: 'POST',
      body: {immatriculation: immatriculation, numeroDossier: numeroDossier},
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authentificationStore.getToken(),
      },
    });
    if (!error.value) {
      toasterStore.afficherLeToaster('success', 'messageSucces.vehiculeAjoute');
      setTimeout(async () => {
        fetchActifs(getIdUser());
        loaderStore.hideLoader();
      }, '2000');
    } else {
      toasterStore.afficherLeToaster('error', 'messageErreur.erreurAjoutVehicule');
      loaderStore.hideLoader();
    }
  };

  const supprimerVehicule = async (immatriculation: string, numeroDossier: string) => {
    loaderStore.showLoader();

    const {error} = await useMyFetch<string>('/copilot/comptes/' + getIdUser() + '/supprimer', {
      method: 'POST',
      body: {immatriculation: immatriculation, numeroDossier: numeroDossier},
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authentificationStore.getToken(),
      },
    });
    if (!error.value) {
      setTimeout(() => {
        fetchActifs(getIdUser());
        toasterStore.afficherLeToaster('success', 'messageSucces.vehiculeSupprime');
        loaderStore.hideLoader();
      }, '2000');
    } else {
      toasterStore.afficherLeToaster('error', 'messageErreur.erreurGenerique');
      loaderStore.hideLoader();
    }
  };

  const supprimerCompte = async () => {
    loaderStore.showLoader();

    const {error} = await useMyFetch<string>('/copilot/comptes/supprimer', {
      method: 'POST',
      query: {email: getUserEmail()},
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authentificationStore.getToken(),
      },
    });
    if (!error.value) {
      authentificationStore.logout();
    } else {
      toasterStore.afficherLeToaster('error', 'messageErreur.erreurGenerique');
    }
    loaderStore.hideLoader();
  };
  return {
    $reset,
    setUser,
    setUserEmail,
    getUser,
    getIdUser,
    sendResetPasswordEmail,
    resetPassword,
    editPassword,
    fetchUser,
    getVehiculesRattaches,
    supprimerVehicule,
    initActifs,
    ajouterVehicule,
    supprimerCompte,
  };
});
