import {defineStore} from 'pinia';

export const useUtilsStore = defineStore('utils', () => {
  const tutoriel = ref<boolean>(JSON.parse(localStorage.getItem('tutoriel') ?? 'null'));

  watch(tutoriel, (newValue) => {
    localStorage.setItem('tutoriel', JSON.stringify(newValue));
  });

  const getTutoriel = () => {
    return tutoriel.value;
  };

  const setTutoriel = (data?: boolean) => {
    tutoriel.value = data ?? false;
  };
  return {
    getTutoriel,
    setTutoriel,
  };
});
