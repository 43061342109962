import {ServiceCopilot, useAuthentificationStore} from '#imports';
export default defineNuxtRouteMiddleware((to) => {
  const authentificationStore = useAuthentificationStore();
  const vehiculeStore = useVehiculeStore();
  if (
    !authentificationStore.isAuthenticated() &&
    to.path !== '/login' &&
    to.path !== '/create-account' &&
    to.path !== '/demande-reset-password' &&
    to.path !== '/reset-password'
  ) {
    return navigateTo('/login');
  }

  const routePermissions = {
    'documents/transmettre-document': ServiceCopilot.TRANSMETTRE_DOCUMENT,
    documents: ServiceCopilot.DOCUMENTS_VEHICULE,
    'releve-kilometrique': ServiceCopilot.RELEVE_KILOMETRIQUE,
    'recherche-prestataire/recherche': ServiceCopilot.TROUVER_PRESTATAIRE,
    'etat-des-lieux': ServiceCopilot.ETAT_DES_LIEUX_VEHICULE,
    'reparation-carrosserie': ServiceCopilot.REPARATION_CARROSSERIE,
  };

  // Get the required permission for the current route
  const requiredPermission = routePermissions[to.path.replace(/^\//, '')];
  // If no specific permission is required for this route, allow access & check if different of 0 bc 0 is RELEVE_KILOMETRIQUE enum
  if (requiredPermission === undefined && requiredPermission !== 0) {
    return;
  }

  // Get user permissions from the store
  const userPermissions = vehiculeStore.getServices();

  // Check if the user has the required permission

  if (!userPermissions.includes(requiredPermission)) {
    // Redirect to a forbidden page or show an error message
    return navigateTo('/401');
  }
});
