import {defineStore} from 'pinia';
import type {PointGPS} from '~/types/types';

export const useAdresseStore = defineStore('adresse', () => {
  const localisation = ref<PointGPS | null>();
  const adresse = ref<string | null>();

  function $reset() {
    localisation.value = null;
    adresse.value = null;
  }

  const setLocalisation = (data?: PointGPS) => (localisation.value = data);
  const setAdresse = (data?: string) => (adresse.value = data);

  const getLocalisation = () => localisation.value;
  const getAdresse = () => adresse.value;

  const isSearching = () => localisation.value !== null && adresse.value !== null;

  return {
    $reset,
    setLocalisation,
    setAdresse,
    getLocalisation,
    getAdresse,
    isSearching,
  };
});
