import {defineStore} from 'pinia';
import type {Toaster} from '~/types/types';

export const useToasterStore = defineStore('toaster', () => {
  const toaster = ref<Toaster>({
    actif: false,
    color: '',
    message: '',
    timeout: 5000,
  });
  const afficherLeToaster = (color: string, message: string) => {
    toaster.value = {actif: true, color, message: useT(message), timeout: 5000};
  };

  const cacherLeToaster = () => {
    toaster.value = {actif: false, color: '', message: '', timeout: 5000};
  };

  const isActif = () => toaster.value.actif;
  const getColor = () => toaster.value.color;
  const getMessage = () => toaster.value.message;

  return {
    toaster,
    afficherLeToaster,
    cacherLeToaster,
    isActif,
    getColor,
    getMessage,
  };
});
