import {defineStore} from 'pinia';
import {useAuthentificationStore} from './authentification';
import type {ReleveKilometrique, Vehicule} from '~/types/typesVehicule';

export const useVehiculeStore = defineStore('vehicule', () => {
  const vehicule = ref<Vehicule | null>(JSON.parse(localStorage.getItem('vehicule') ?? 'null'));

  const relevesKilometriques = ref<ReleveKilometrique[]>([]);
  const authentificationStore = useAuthentificationStore();
  const fournisseursStore = useFournisseursStore();
  const toasterStore = useToasterStore();
  const loaderStore = useLoaderStore();

  watch(vehicule, (newValue) => {
    localStorage.setItem('vehicule', JSON.stringify(newValue));
  });

  function $reset() {
    vehicule.value = null;
    localStorage.removeItem('vehicule');
  }

  const setVehicule = (data?: Vehicule) => (vehicule.value = data);

  const setAlertes = (data?: Alerte[]) => (vehicule.value = {...vehicule.value, alertes: data});

  const setRelevesKilometriques = (data?: ReleveKilometrique[]) => (relevesKilometriques.value = data);

  const getIdActif = () => vehicule.value?.id ?? '';

  const getNumeroDossier = () => vehicule.value?.numeroDossier ?? '';

  const getImmatriculation = () => vehicule.value?.immatriculation ?? '';

  const getRelevesKilometriques = () => relevesKilometriques.value;

  const getDernierReleveKilometrique = () => vehicule.value?.dernierReleveKilometrique ?? 0;

  const getProchainesVisites = () => getVisites(vehicule.value?.alertes, vehicule.value);

  const getPrestationsCopilot = () => vehicule.value?.prestationsCopilot ?? [];

  const getServices = () => vehicule.value?.services ?? [];

  const getVehicule = (): Vehicule | null => vehicule.value;
  const getCommentaire = (prestation: string) => vehicule.value?.commentaires[prestation];

  const fetchVehicule = async (id: string) => {
    loaderStore.showLoader();

    const paramIdVehicule = id ? `?paramIdVehicule=${id}` : '';
    const {data, error} = await useMyFetch<Vehicule>('/copilot/vehicules' + paramIdVehicule, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authentificationStore.getToken(),
      },
    });

    if (data.value) {
      const services: ServiceCopilot[] = data.value.services.map((service) => ServiceCopilot[service]);
      const vehicule = {...data.value, services};
      setVehicule(vehicule);
      await useMyFetch<Alerte[]>('/copilot/vehicules/' + getIdActif() + '/alertes', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authentificationStore.getToken(),
        },
      }).then((response) => setAlertes(response.data));
      fournisseursStore.fetchFournisseurFavori();
    }
    if (error.value) {
      toasterStore.afficherLeToaster('error', 'messageErreur.erreurRecupInfosVehicule');
    }
    loaderStore.hideLoader();
  };

  const fetchDerniersRelevesKilometriques = async () => {
    const {data, error} = await useMyFetch<ReleveKilometrique[]>(
      '/copilot/vehicules/' + vehicule.value?.id + '/recupererDerniersReleves',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authentificationStore.getToken(),
        },
      },
    );
    if (data.value) {
      setRelevesKilometriques(data.value);
    } else if (error.value) {
      toasterStore.afficherLeToaster('error', 'messageErreur.erreurRecupInfosVehicule');
    }
  };

  const isServiceAutorise = (service: ServiceCopilot): boolean => {
    return vehicule?.value?.services.includes(service) ?? false;
  };

  const prochaineVisite = () => {
    if (!vehicule.value) return null;
    const visites = getVisites(vehicule.value.alertes, vehicule.value);
    if (visites.length) {
      if (visites.filter((visite) => visite.alerte === 'error').length === 1) {
        return visites.filter((visite) => visite.alerte === 'error')[0];
      }

      return visites[0];
    }

    return null;
  };

  const ajouterReleveKilometrique = async (releveKilometrique: number) => {
    loaderStore.showLoader();

    const {error} = await useMyFetch<string>(
      '/copilot/vehicules/' + vehicule.value?.id + '/ajouterReleveKilometrique',
      {
        method: 'POST',
        body: {kilometrage: releveKilometrique, source: 'Copilot', date: new Date(Date.now())},
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authentificationStore.getToken(),
        },
      },
    );
    if (!error.value) {
      fetchVehicule(vehicule.value?.id);
      toasterStore.afficherLeToaster('success', 'messageSucces.majReleveKilometrique');
    } else {
      toasterStore.afficherLeToaster('error', 'messageErreur.erreurAjoutReleveKilometrique');
    }
    loaderStore.hideLoader();
  };

  const ajouterEtatDesLieux = async (values: object) => {
    loaderStore.showLoader();

    const {error} = await useMyFetch<string>('/copilot/vehicules/' + vehicule.value?.id + '/ajouterEtatDesLieux', {
      method: 'POST',
      body: values,
      headers: {
        Authorization: 'Bearer ' + authentificationStore.getToken(),
      },
    });
    if (error.value) {
      toasterStore.afficherLeToaster('error', 'messageErreur.erreurAjoutEtatDesLieux');
    } else {
      toasterStore.afficherLeToaster('success', 'messageSucces.etatDesLieuxEnvoye');
    }
    loaderStore.hideLoader();
  };

  return {
    $reset,
    vehicule,
    setVehicule,
    getVehicule,
    getIdActif,
    getNumeroDossier,
    getImmatriculation,
    getPrestationsCopilot,
    getRelevesKilometriques,
    getProchainesVisites,
    getCommentaire,
    getServices,
    getDernierReleveKilometrique,
    isServiceAutorise,
    fetchVehicule,
    prochaineVisite,
    fetchDerniersRelevesKilometriques,
    ajouterReleveKilometrique,
    ajouterEtatDesLieux,
  };
});
